import { Trans } from 'react-i18next';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'

import { MINIMAL_PAGE_ITEM_COUNT } from 'Config';

interface PaginationProps {
  meta: ResponseMeta | undefined;
  page: number;
  onPageChange: (p: number) => void
}

function Pagination({ meta, page, onPageChange }: PaginationProps) {
  function generatePageItems(width: number) {
    const current = meta?.current_page || 1;
    const total = meta?.last_page || 1;

    if (width < MINIMAL_PAGE_ITEM_COUNT) {
      throw new Error(`Must allow at least ${MINIMAL_PAGE_ITEM_COUNT} page items`);
    }

    if (width % 2 === 0) {
      throw new Error(`Must allow odd number of page items`);
    }

    if (total < width) {
      return [...new Array(total).keys()];
    }

    const left = Math.max(0, Math.min(total - width, current - Math.floor(width / 2)));
    const items: (string | number)[] = new Array(width);

    for (let i = 0; i < width; i += 1) {
      items[i] = i + left;
    }

    // replace non-ending items with placeholders
    if (items[0] > 0) {
      items[0] = 0;
      items[1] = 'prev-more';
    }

    if (items[items.length - 1] < total - 1) {
      items[items.length - 1] = total - 1;
      items[items.length - 2] = 'next-more';
    }

    return items;
  }

  const pages = generatePageItems(7);

  const from = meta?.from ?? 0;
  const to = meta?.to ?? 0;
  const total = meta?.total ?? 0;

  return (
    <div className="py-6">
      <nav className="border-t border-gray-200 pb-4 flex items-center flex-col md:flex-row">

        <div className="order-2 w-full whitespace-nowrap md:w-0 md:order-none">
          <p className="pt-6 text-gray-700 text-sm text-center sm:pt-2 md:pt-4">
            <Trans i18nKey="pagination.meta">
              Showing <span className="text-gray-900 font-medium">{{from}}</span> - <span className="text-gray-900 font-medium">{{to}}</span> of <span className="text-gray-900 font-medium">{{total}}</span> results
            </Trans>
          </p>
        </div>

        <div className="order-1 w-full flex items-center mx-auto justify-center md:order-none">

          <button
            disabled={page <= 1}
            onClick={() => onPageChange(page - 1)}
            className="group pt-2 pr-1 inline-flex items-center lg:pt-4"
          >
            <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-700" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </button>

          <ul className="flex items-center list-none p-0">
            {pages.map(page =>
              typeof page === 'number' ? (
                <li key={page} className="px-1 sm:px-2 lg:px-4">
                  {meta?.current_page === page + 1 ? (
                    <span
                      className="border-blue-500 text-blue-600 border-t-2 pt-2 px-1 sm:px-2 lg:pt-4 lg:px-4 inline-flex items-center text-sm font-medium"
                      aria-current="page"
                    >
                      {page + 1}
                    </span>
                  ):(
                    <button
                      onClick={() => onPageChange(page + 1)}
                      className="border-transparent text-gray-500 border-t-2 pt-2 px-1 sm:px-2 lg:pt-4 lg:px-4 inline-flex items-center text-sm font-medium hover:text-gray-700 hover:border-gray-300"
                    >
                      {page + 1}
                    </button>
                  )}
                </li>
              ):(
                <li
                  key={page}
                  className="border-transparent text-gray-500 border-t-2 pt-2 px-1 sm:px-2 lg:pt-4 lg:px-4 inline-flex items-center text-sm font-medium"
                >
                  <span>…</span>
                </li>
              )
            )}
          </ul>

          <button
            disabled={meta?.last_page === page}
            onClick={() => onPageChange(page + 1)}
            className="group pt-2 pr-1 inline-flex items-center lg:pt-4"
          >
            <span className="sr-only">Next</span>
            <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-500 group-hover:text-gray-700" aria-hidden="true" />
          </button>

        </div>
      </nav>
    </div>
  )
}

export default Pagination
