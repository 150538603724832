import { SITE_NAME } from "Config";

export default function Footer() {
  return (
    <div className="mt-12 bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
        &copy; {(new Date().getFullYear())} { SITE_NAME }. All rights reserved.
      </div>
    </div>
  )
}
