import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

import { GOOGLE_ANALYTICS_CODE } from "Config";

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      if (GOOGLE_ANALYTICS_CODE) {
        ReactGA.initialize(GOOGLE_ANALYTICS_CODE);
        setInitialized(true);
      }
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export default usePageTracking;
