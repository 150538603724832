import pluralize from 'pluralize';

export const formatWeight = (value: number) => {
  const kgToTons = value / 1000;

  const formatter = new Intl.NumberFormat('en', {
    minimumFractionDigits: kgToTons <= 1 ? 4 : 0,
    maximumFractionDigits: kgToTons <= 1 ? 4 : 0,
  });

  return `${formatter.format(kgToTons)} ${pluralize('Ton', Math.round(kgToTons))}`;
};

export const formatCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });


  return formatter.format(Math.round(value));
};

export const formatCount = (value: number) => {
  const formatter = new Intl.NumberFormat('en');
  return formatter.format(value);
};

export const formatDov = (value: number) => {
  const formatter = new Intl.NumberFormat('en');
  return `${formatter.format(Math.round(value))} DOV`;
};

export const formatDelta = (value: number | null) => {
  if (value !== null && value !== undefined) {
    return value.toFixed(2);
  }

  return null;
};
