import { Switch, Route } from 'react-router-dom';

import Header from "Components/Header";
import Footer from "Components/Footer";
import usePageTracking from "Hooks/usePageTracking";
import routes from "Routes";

function App() {
  usePageTracking();

  return (
    <div className="flex flex-col justify-between min-h-screen bg-gray-50">
      <Header/>
      <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Switch>
          {routes.map((route, index) => (
            <Route key={index} {...route} />
          ))}
        </Switch>
      </div>
      <Footer/>
    </div>
  )
}

export default App;
