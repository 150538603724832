import merge from "lodash.merge";

import { API_URL } from "Config";

export async function request({
  endpoint,
  method = 'GET',
  body,
  config = {},
}: APIConfig) {
  const url = endpoint.startsWith('http')
    ? endpoint
    : `${API_URL}${endpoint}`;

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  const defaultConfig: RequestInit = {
    method,
    headers: {
      ...headers,
    },
  };

  if (body) {
    defaultConfig.body = config?.headers ? body : JSON.stringify(body);
  }

  try {
    const response = await fetch(url, merge(defaultConfig, config));
    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export function get(endpoint: string, config?: RequestInit) {
  return () => {
    return request({endpoint, method: 'GET', config});
  };
}

export function post(endpoint: string, config?: RequestInit) {
  return (variables?: any) => {
    return request({endpoint, method: 'POST', body: variables, config});
  };
}

export function put(endpoint: string, config?: RequestInit) {
  return (variables?: any) => {
    return request({endpoint, method: 'PUT', body: variables, config});
  };
}
