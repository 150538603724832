import { useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from 'react-i18next';
import qs from "qs";
import colors from 'tailwindcss/colors';
//@ts-ignore
import { Chart, Lines } from 'rumble-charts';

import {
  RESULTS_PER_PAGE,
  SORT_BY_ASC,
  SORT_BY_DESC
} from 'Config';
import { trackEvent } from 'Helpers/analytics';
import { get } from 'Helpers/api';
import {
  formatCount,
  formatCurrency,
  formatDov,
  formatWeight
} from 'Helpers/formatter';
import useDebounce from 'Hooks/useDebounce';

import Heal from "Assets/Images/heal.png";
import Delta from "Components/Delta";
import GlobalDebt from "Components/GlobalDebt";
import Loader from "Components/Loader";
import Logo from "Components/Logo";
import Pagination from "Components/Pagination";
import GetInTouch from "Components/GetInTouch";
import Table from "Components/Table";

function Home() {
  const { t } = useTranslation();
  const [ page, setPage ] = useState<number>(1)
  const [ perPage, setPerPage ] = useState<number>(RESULTS_PER_PAGE);
  const [ sortKey, setSortKey ] = useState<SortKeys>("rank");
  const [ sortDirection, setSortDirection ] = useState<
    typeof SORT_BY_DESC | typeof SORT_BY_ASC
  >(SORT_BY_ASC);
  const [ term, setTerm ] = useState("");
  const searchTerm = useDebounce(term, 500);

  const params = qs.stringify({
    sortby: sortKey,
    sortdir: sortDirection,
    limit: perPage,
    page,
    ...(term && { filter: term })
  });

  const { isLoading, error, data } = useQuery<
    APIPaginatedResponse<Contract[]>,
    APIErrorResponse
  >(
    ['market-contracts', { page, perPage, sortKey, sortDirection, searchTerm }],
    get(`market/contracts?${params}`),
    { keepPreviousData : true }
  );

  const sort = (key: SortKeys) => {
    if (sortKey === key) {
      setSortDirection(sortDirection === SORT_BY_ASC ? SORT_BY_DESC : SORT_BY_ASC);
      return false;
    }

    setSortKey(key)

    trackEvent({
      category: 'Contracts',
      action: `Sort ${sortDirection === SORT_BY_ASC ? 'Ascending' : 'Descending'}`,
      label: key,
    })
  }

  const onSetPageLimit = (value: number) => {
    setPerPage(value);
    trackEvent({ category: 'Contracts', action: 'Set Page Limit', value })
  }

  const onPageChange = (page: number) => {
    setPage(page)
    trackEvent({ category: 'Contracts', action: 'Change Page', value: page })
  }

  const search = (value: string) => {
    setTerm(value)
    trackEvent({ category: 'Contracts', action: 'Filter', label: value })
  };

  if (isLoading) {
    return <Loader/>;
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="mb-4 p-4 bg-blue-500 rounded-full">
          <img className="w-24 h-auto" src={Heal} alt=""/>
        </div>
        <h3 className="text-2xl tracking-tight font-bold text-gray-900">{t('error.title')}</h3>
        <p>{t('error.body')}</p>
      </div>
    )
  }

  return (
    <>
      <div className="py-10 space-y-2">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900">
          {t("global-debt.title")}
        </h1>
        <GlobalDebt />
      </div>

      <div className="mb-6 flex items-center justify-between space-x-1">
        <div className="flex items-center justify-center space-x-2 whitespace-nowrap">
          <label htmlFor="rows" className="block text-sm font-medium text-gray-500">{t("table.options.row_display.label")}</label>
          <select
            name="rows"
            value={perPage}
            onChange={(e) => onSetPageLimit(parseInt(e.target.value))}
            className="block w-full pl-3 pr-8 py-1.5 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
          >
            {[25, 50, 75, 100].map(value => (
              <option key={value} {...{ value }}>{value}</option>
            ))}
          </select>
        </div>

        <div className="flex-1 flex items-center justify-center space-x-2 whitespace-nowrap sm:flex-none">
          <label htmlFor="search" className="sr-only">{t("table.options.search.label")}</label>
          <div className="relative w-full">
            <input
              type="search"
              name="search"
              placeholder={t("table.options.search.label")}
              onChange={(e) => search(e.target.value)}
              className="block w-full shadow-sm appearance-none pl-3 pr-10 py-1.5 text-gray-600 text-sm placeholder-gray-500 border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
            <svg width="20" height="20" fill="none" className="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-400 pointer-events-none">
              <circle cx="8.5" cy="8.5" r="5.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
              <path d="M17.25 17.25L13 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div>
        </div>
      </div>

      <Table>
        <colgroup>
          <col className="w-12 min-w-[auto] max-w-[auto]"/>
          <col className="w-full min-w-[auto] max-w-[250px]"/>
          <col className="w-60 min-w-[auto] max-w-[auto]"/>
          <col className="w-20 min-w-[auto] max-w-[auto]"/>
          <col className="w-20 min-w-[auto] max-w-[auto]"/>
          <col className="w-52 min-w-[auto] max-w-[auto]"/>
          <col className="w-5 min-w-[auto] max-w-[auto]"/>
          <col className="w-5 min-w-[auto] max-w-[auto]"/>
        </colgroup>
        <thead>
          <tr>
            <Table.Header
              id="rank"
              {...{sortKey, sortDirection}}
              className="hidden md:table-cell"
              sort={sort}
            >
              <>
                #
                <span className="sr-only">{t("rank")}</span>
              </>
            </Table.Header>
            <Table.Header
              id="name"
              {...{sortKey, sortDirection}}
              className="left-0 z-20 after:w-3 after:h-full after:absolute after:top-0 after:-right-3 after:z-[-1] after:bg-gradient-to-r after:from-white"
              sort={sort}
            >
              {t("name")}
            </Table.Header>
            <Table.Header
              id="transactions_count"
              {...{sortKey, sortDirection}}
              align="right"
              sort={sort}
            >
              {t("tx_count")}
            </Table.Header>
            <Table.Header
              id="percent_day_change"
              {...{sortKey, sortDirection}}
              align="right"
              sort={sort}
            >
              {t("percent_day_change")}
            </Table.Header>
            <Table.Header
              id="percent_week_change"
              {...{sortKey, sortDirection}}
              align="right"
              sort={sort}
            >
              {t("percent_week_change")}
            </Table.Header>
            <Table.Header
              id="total_carbon_debt"
              {...{sortKey, sortDirection}}
              align="right"
              sort={sort}
            >
              {t("total_carbon_debt")}
            </Table.Header>
            <Table.Header
              id="cost_to_offset"
              {...{sortKey, sortDirection}}
              align="right"
              sort={sort}
            >
              {t("cost_to_offset")}
            </Table.Header>
            <Table.Header
              id="dov_to_offset"
              {...{sortKey, sortDirection}}
              align="right"
              sort={sort}
            >
              {t("dov_to_offset")}
            </Table.Header>
            <Table.Header align="right">
              {t("last_7_days")}
            </Table.Header>
          </tr>
        </thead>
        <tbody className="relative bg-white">
          {data?.data.map(contract => (
            <tr key={contract.id}>
              <Table.Cell align="center" className="hidden sticky left-1 md:table-cell">
                {contract.rank}
              </Table.Cell>
              <Table.Cell className="sticky left-0 md:left-[68px] lg:static">
                <div className="flex items-center after:w-3 after:h-full after:absolute after:top-0 after:-right-3 after:z-[-1] after:bg-gradient-to-r after:from-white">
                  <span className="flex justify-center items-center bg-gray-100 rounded-md text-gray-400 text-xs w-4 h-4 mr-3 md:hidden">
                    {contract.rank}
                  </span>
                  <div className="flex-shrink-0 h-8 w-8 bg-gray-200 rounded-full overflow-hidden md:h-10 md:w-10">
                    <Logo id={contract.id} alt={contract.name} />
                  </div>
                  <div className="flex-1 ml-4 max-w-[150px]">
                    <p className="truncate font-medium text-gray-900 text-base md:text-lg">{contract.name}</p>
                    <span className="truncate text-gray-500 text-sm md:text-base">{contract.symbol}</span>
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell align="right">
                {formatCount(contract.transactions_count)}
              </Table.Cell>
              <Table.Cell align="right">
                <Delta
                  className="px-2 py-1 rounded-md font-medium"
                  value={contract.daily_aggregates[contract.daily_aggregates.length - 1]?.percent_day_change}
                />
              </Table.Cell>
              <Table.Cell align="right">
                <Delta
                  className="px-2 py-1 rounded-md font-medium"
                  value={contract.daily_aggregates[contract.daily_aggregates.length - 1]?.percent_week_change}
                />
              </Table.Cell>
              <Table.Cell align="right">
                {formatWeight(contract.total_carbon_debt)}
              </Table.Cell>
              <Table.Cell align="right">
                {formatCurrency(contract.cost_to_offset)}
              </Table.Cell>
              <Table.Cell align="right">
                {formatDov(contract.dov_to_offset)}
              </Table.Cell>
              <Table.Cell align="right">
                <Chart
                  width={120}
                  height={50}
                  series={[{ data: contract.daily_aggregates.map(aggregate => aggregate.day_change) }]}
                  scaleY={{paddingTop: 10, paddingBottom: 10}}>
                  <Lines
                    lineWidth={2}
                    colors={() => {
                      const week = contract.daily_aggregates[contract.daily_aggregates.length - 1]?.percent_week_change;

                      if (week && week <= 0) {
                        return colors.green[800];
                      }

                      return colors.red[800];
                    }} />
                </Chart>
              </Table.Cell>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination
        page={page}
        meta={data?.meta}
        onPageChange={page => onPageChange(page)}
      />

      <GetInTouch/>
    </>
  )
}

export default Home;
