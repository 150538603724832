import numeral from "numeral";

numeral.register('format', 'tonnes', {
    regexps: {
        format: /(t)/,
        unformat: /(t)/
    },
    format: function(value, format, roundingFunction) {
        var output,
            suffixes = ['T', 'Kt', 'Mt', 'Gt', 'Tt', 'Pt', 'Et'],
            //@ts-ignore
            suffix = numeral._.includes(format, ' t') ? ' ' : '',
            power,
            min,
            max;

        format = format.replace(/\s?\t/, '');

        value = value / 1000;

        for (power = 0; power <= suffixes.length; power++) {
          min = Math.pow(1000, power);
          max = Math.pow(1000, power + 1);

          if (value === null || value === 0 || (value >= min && value < max)) {
            suffix += suffixes[power];

            if (min > 0) {
              value = value / min;
            }

            break;
          }
        }

        //@ts-ignore
        output = numeral._.numberToFormat(value, format, roundingFunction);

        return output + suffix;
    },
    unformat: function(string) {
      //@ts-ignore
      return numeral._.stringToNumber(string) * 0.01;
    }
});

export default numeral;
