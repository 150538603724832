import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import { QueryClient, QueryClientProvider } from "react-query";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";

import App from "Components/App";
import i18n from "Helpers/i18n";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

export default function AppProvider() {
  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider {...{ i18n }}>
        <Router>
          <App />
        </Router>
      </I18nextProvider>
    </QueryClientProvider>
  );
}
