import classnames from "classnames";

import { SORT_BY_ASC, SORT_BY_DESC } from 'Config';

interface Props extends React.HTMLProps<HTMLTableHeaderCellElement> {
  id?: SortKeys;
  sortKey?: SortKeys;
  sortDirection?: typeof SORT_BY_DESC | typeof SORT_BY_ASC;
  align?: "left" | "right";
  sort?: (id: SortKeys) => void;
}

export default function Header({
  id,
  sortKey,
  sortDirection,
  align = "left",
  sort,
  className,
  children
}: Props) {

  const Content = () => {
    return (
      <div className={
        classnames(
          "flex flex-row items-center",
          { "justify-start": align === "left" },
          { "justify-end": align === "right" },
        )
      }>
          {sortKey && align === "right" && (
            <svg className="mr-1 w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
              {(sortKey === id) && ( sortDirection === SORT_BY_ASC ? (
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"></path>
              ) : (
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
              ))}
            </svg>
          )}

          {children}

          {sortKey && align === "left" && (
            <svg className="ml-1 w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
              {(sortKey === id) && ( sortDirection === SORT_BY_ASC ? (
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"></path>
              ) : (
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
              ))}
            </svg>
          )}
      </div>
    )
  }

  return (
    <th
      className={
        classnames(
          "sticky top-0 px-2 py-1 bg-white border-0 border-b border-gray-200 lg:px-4 lg:py-2 lg:z-10",
          { "text-left": align === "left" },
          { "text-right": align === "right" },
          className
        )
      }
    >
      {sort ? (
        <button
          onClick={() => (id) && sort(id)}
          className="whitespace-nowrap text-sm font-medium text-gray-500 leading-none uppercase tracking-wider"
        >
          <Content/>
        </button>
      ) : (
        <div className="whitespace-nowrap text-sm font-medium text-gray-500 leading-none uppercase tracking-wider">
          <Content/>
        </div>
      )}
    </th>
  )
};
