import classnames from "classnames";

interface Props extends Partial<HTMLTableCellElement> {
  align?: "left" | "right" | "center";
  children: any;
}

export default function Cell({ align, children, className }: Props) {
  return (
    <td
      className={
        classnames(
          "p-2 whitespace-nowrap bg-white border-b border-gray-200 text-gray-500 lg:text-lg lg:px-4 lg:py-4",
          { "text-left": !align },
          { "text-right": align === "right" },
          { "text-center": align === "center" },
          className
        )
      }
    >
      {children}
    </td>
  )
};
