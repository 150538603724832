import { useTranslation } from 'react-i18next';
import qs from 'qs';

import Montage from "Assets/Images/montage.jpg";
import { GET_IN_TOUCH_MAILTO, GET_IN_TOUCH_SUBJECT } from "Config";
import GetInTouchRef from 'Refs/GetInTouch';

export default function GetInTouch() {
  const { t } = useTranslation();

  const params = qs.stringify({ subject: GET_IN_TOUCH_SUBJECT });

  return (
    <div ref={GetInTouchRef} className="relative py-6 rounded-md bg-[#F4F2F3] overflow-hidden lg:flex lg:items-center lg:justify-center">
      <div className="hidden w-1/4 aspect-h-1 lg:block">
        <img className="w-full h-auto" src={Montage} alt=""/>
      </div>
      <div className="lg:w-3/4">
        <div className="w-full mx-auto px-12 py-6 lg:max-w-3xl md:flex md:items-center">
          <div className="md:flex-1">
            <h2 className="mb-4 text-3xl font-semibold text-gray-900 lg:text-5xl">
              {t("get_in_touch.title")}
            </h2>
            <p className="text-xl text-gray-500 leading-relaxed">
              {t("get_in_touch.body")}
            </p>
          </div>

          <a
            href={`mailto:${GET_IN_TOUCH_MAILTO}?${params}`}
            className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 text-lg border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto"
          >
            {t('get_in_touch.cta')}
          </a>
        </div>
      </div>
    </div>
  )
}
