import classnames from "classnames";

import { formatDelta } from 'Helpers/formatter';

interface DeltaProps extends Partial<HTMLSpanElement> {
  value: number | null;
}

export default function Delta(props: DeltaProps) {
  const { value } = props;

  const indicator = () => {
    // Value is negative
    if (value && Math.sign(value) === -1) {
      return (
        <svg className="w-3 h-3 mr-0.5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
        </svg>
      )
    }

    // Value is positive
    if (value && Math.sign(value)) {
      return (
        <svg className="w-3 h-3 mr-0.5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"></path>
        </svg>
      )
    }
  }

  return (
    <span
      className={
        classnames(
          "inline-flex items-center",
          props.className,
          {
            "bg-gray-100 text-gray-800": !value,
            "text-red-800": value && value > 0,
            "text-green-800": value && value < 0,
          }
        )
      }
    >
      {value ? (
        <>
          {indicator()}
          {formatDelta(Math.abs(value))}%
        </>
      ) : "--"}
    </span>
  );
}
