import { useMediaQuery } from 'react-responsive'
import classnames from "classnames";

import Header from "Components/Table/Header";
import Cell from "Components/Table/Cell";

interface Props {
  children: JSX.Element|JSX.Element[];
}

function Table({ children }: Props) {
  // @TODO - Get value from Tailwind
  //
  // The max-width value should match the value of the parent container.
  // What needs to happen is we need to look into Tailwind and grab the value
  // of max-w-7xl and use that as the max-width value.
  //
  // Tried the following but ran into issues with importing a file from outside the
  // baseUrl, as tailwind.config.js leave outside of src.
  // https://tailwindcss.com/docs/configuration#referencing-in-java-script
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 80rem)" });

  return (
    <div
      className={
        classnames("bg-white",{
          'overflow-x-scroll': isTabletOrMobile,
        })
      }
    >
      <table className="min-w-full border-separate border-spacing-0">
        { children }
      </table>
    </div>
  )
};

Table.Header = Header;
Table.Cell = Cell;

export default Table;
