import Pages from "Pages";

const routes = [
  {
    path: "/",
    exact: true,
    component: Pages.Home
  }
];

export default routes;
