import i18next from "i18next";

import EN from "Internationalization/en.json";

const resources = {
  en: {
    translation: EN
  },
};

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources,
  react:{
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span'],
  }
});

export default i18next;
