import { useTranslation } from 'react-i18next';

interface Props extends Partial<HTMLDivElement> {}

export default function Loader(props: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center">
      <svg className="w-12 h-12 mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 273 273">
        <g fill="none" fillRule="evenodd">
          <path fill="#000000" d="M136.274 273c75.263 0 136.274-61.01 136.274-136.274C272.548 61.462 211.537.451 136.274.451 61.01.451 0 61.462 0 136.726 0 211.99 61.01 273 136.274 273"></path>
          <path fill="#FFFFFE" d="M136.355 69.049c5.711 7.747 11.256 15.218 16.741 22.733 6.583 9.017 12.598 18.419 17.465 28.445 7.487 15.417 6.665 30.431-3.874 44.286-6.003 7.891-14.237 12.454-24.02 14.104-1.153.193-1.296.624-1.291 1.617.033 5.7.039 11.405-.011 17.111-.022 2.229-1.484 3.983-3.587 4.568-2.08.575-4.331-.187-5.413-2.069-.496-.855-.81-1.937-.822-2.924-.077-5.458-.038-10.909-.038-16.366v-1.33c-3.581-1.126-7.107-1.849-10.313-3.294-13.314-5.998-21.101-16.366-23.23-30.795-1.308-8.856.838-17.183 5.236-24.831 3.94-6.864 8.238-13.524 12.542-20.168 6.28-9.678 12.703-19.262 19.07-28.885.403-.618.849-1.214 1.545-2.202zm5.088 99.63c.529-.071.877-.077 1.203-.159 11.03-2.815 18.484-9.569 21.563-20.543 2.488-8.884.96-17.399-3.537-25.487-5.639-10.137-11.852-19.893-18.7-29.251-.115-.154-.319-.248-.529-.402v75.842zm-33.345-33.14c-3.068 17.851 10.777 31.744 23.407 32.981 0-2.136.071-4.266-.039-6.39-.044-.757-.353-1.65-.855-2.202-4.894-5.396-9.855-10.737-14.805-16.084-2.516-2.721-5.037-5.43-7.708-8.305zm23.407 10.671c0-4.502.038-8.552-.039-12.602-.017-.684-.375-1.529-.86-2.02-4.299-4.392-8.663-8.718-13.012-13.06-.369-.37-.755-.723-1.274-1.209-1.54 2.616-3.002 5.11-4.475 7.62 6.527 7.057 12.944 14.005 19.66 21.271zm-.066-52.005l-9.326 14.28c3.184 3.239 6.257 6.351 9.326 9.469V94.205z"></path>
          <path className="origin-center animate-spin" fill="#FFFFFE" d="M135.002 41.499c18.7.187 34.867 4.513 49.793 13.325 3.129 1.843 4.795 5.176 4.299 8.344-.585 3.752-2.947 6.472-6.479 7.289-2.201.507-4.326.198-6.3-.971-9.409-5.59-19.556-9.116-30.41-10.385-18.738-2.185-36.247 1.595-52.049 11.963-18.933 12.415-30.592 29.928-34.576 52.237-3.343 18.69.006 36.357 9.491 52.817 1.606 2.781 2.097 5.573.745 8.498-1.434 3.101-3.984 4.794-7.388 4.905-3.272.11-5.855-1.308-7.565-4.139-4.155-6.902-7.494-14.197-9.596-21.972-8.773-32.478-2.709-62.009 18.673-87.965 14.749-17.911 33.973-28.655 56.922-32.556 1.804-.309 3.614-.634 5.44-.789 3.454-.292 6.92-.469 9-.601M231.458 135.586c.017 44.855-29.697 83.011-72.455 93.5-24.234 5.948-47.387 2.561-69.282-9.391-3.217-1.76-5.054-5.303-4.563-8.52.568-3.729 3.068-6.5 6.676-7.31 2.169-.487 4.216-.079 6.152 1.02 8.995 5.07 18.646 8.227 28.875 9.452 13.707 1.645 27.06.017 39.708-5.556 25.784-11.362 41.482-31.044 46.686-58.7 3.404-18.109.221-35.375-8.663-51.548-2.489-4.529-1.209-9.573 3.062-11.951 4.254-2.373 9.237-.756 11.753 3.752 6.738 12.068 10.589 25.012 11.742 38.763.21 2.467.237 4.949.309 6.489"></path>
        </g>
      </svg>
      <span className="font-medium uppercase text-sm text-gray-600">
        {t('loading')}
      </span>
    </div>
  );
}
