export const API_URL = process.env.REACT_APP_API_URL;
export const SITE_DESCRIPTION = process.env.REACT_APP_SITE_DESCRIPTION;
export const SITE_NAME = process.env.REACT_APP_SITE_NAME;
export const GOOGLE_ANALYTICS_CODE = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE;
export const GET_IN_TOUCH_MAILTO = process.env.REACT_APP_GET_IN_TOUCH_MAILTO;
export const GET_IN_TOUCH_SUBJECT = process.env.REACT_APP_GET_IN_TOUCH_SUBJECT;

// Newsletter Signup
export const MAILCHIMP_SIGNUP_URL = process.env.REACT_APP_MAILCHIMP_SIGNUP_URL

// Pagination
export const MINIMAL_PAGE_ITEM_COUNT = 7;
export const RESULTS_PER_PAGE = 25;

// Sorting
export const SORT_BY_ASC = "asc";
export const SORT_BY_DESC = "desc";
