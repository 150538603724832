import { Trans } from 'react-i18next';
import { OutboundLink } from "react-ga";
import { useQuery } from "react-query";

import Delta from 'Components/Delta';
import numeral from 'Helpers/numeral';
import { get } from 'Helpers/api';

export default function GlobalDebt() {
  const { isSuccess, data } = useQuery<
    GlobalDebt[],
    APIErrorResponse
  >(
    'total-carbon-debt',
    get(`market/debt`),
  );

  if (isSuccess && data && data.length) {
    const [ stat ] = data;
    const trend = Math.sign(stat.percent_day_change) === -1 ? "decrease" :
      Math.sign(stat.percent_day_change) ? "increase" : "change";

    const currency = numeral(stat.cost_to_offset).format('$0.00a');
    const weight = numeral(stat.total_carbon_debt).format('0.0t');

    return (
      <>
        <p className="text-lg text-gray-500">
          <Trans i18nKey="global-debt.meta">
            The total Ethereum carbon debt is {{ weight }}* CO2e which cost {{ currency }} to offset, <Delta value={stat.percent_day_change} /> {{ trend }} in emissions in the last day.
          </Trans>
        </p>
        <p className="text-sm text-gray-500">
          *
          <Trans i18nKey="global-debt.disclaimer">
            Tracked projects only.
            <OutboundLink
              eventLabel="digiconomist.ethereum-energy-consumption"
              to="https://digiconomist.net/ethereum-energy-consumption/"
              target="_blank"
              rel="nofollow"
              className="underline"
            >
              Actual figures may be higher.
            </OutboundLink>
          </Trans>
        </p>
      </>
    )
  }

  return null;
}
