import { Transition } from '@headlessui/react'
import { Img } from 'react-image'

interface LogoProps extends Partial<HTMLImageElement> {
  id: string;
  alt : string;
}

export default function Logo({ id, alt }: LogoProps) {
  const src = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${id}/logo.png`;

  return (
    <Img
      key={src}
      {...{ src, alt }}
      container={(children) => {
        return (
          <Transition
            appear={true}
            show={true}
            enter="transition-opacity duration-1000"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {children}
          </Transition>
        )
      }}
    />
  );
}
